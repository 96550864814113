<template>
  <div>
    <service-bar></service-bar>
    <TopParallax
      title="CALENDAR"
      :img-src="imgSrc"
    />
    <v-container>
      <FullCalendar class="py-8" />
    </v-container>
  </div>
</template>

<script>
import ServiceBar from '@/components/ServiceBar.vue'
import TopParallax from '@/components/TopParallax.vue'
import FullCalendar from '@/components/FullCalendar.vue'

export default {
  components: {
    TopParallax,
    ServiceBar,
    FullCalendar
  },
  data() {
    return {
      imgSrc: require('@/assets/images/calendar_banner.jpg')
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Calendar'
      }
    }
  }
}
</script>
